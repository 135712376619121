export default [
  {
    path: '/dashboard/pesanan',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'order-index',
        component: () => import('@/views/dashboard/order/list/page-list.vue'),
      },
      {
        path: 'buat',
        name: 'order-create-old',
        component: () =>
          import('@/views/dashboard/order/create/page-create.vue'),
      },
      {
        path: ':id',
        name: 'order-detail',
        props: true,
        component: () => import('@/views/dashboard/order/show/page-detail.vue'),
      },
    ],
  },
]
